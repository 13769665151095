/* -----------------------------------------------------------------------------
 *
 * Molecules: Panel
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

.panel {
  padding: 32px 24px;
  border-radius: 8px;
  background-color: var( --color-mine-shaft-1 );
}

.title {
  padding: 16px 8px;
  border-radius: 6px;
  background-color: var( --color-black );
  color: var( --color-cyan-1 );
  text-align: center;
  text-transform: uppercase;

  @media (--small-max) {
    font-size: rem( 24px );
  }

  @media (--medium) {
    padding: 16px 24px;
  }
}

.content {

}
