/* -----------------------------------------------------------------------------
 *
 * Organisms: ContactForm
 *
 * ----------------------------------------------------------------------------- */

@import '../../../styles/common.css';

/* -----------------------------------------------------------------------------
 * Content
 * ----------------------------------------------------------------------------- */

.content {
  color: var( --color-mercury );
  text-align: center;
}

.backHomeBtn {
  margin-top: 24px;
}

/* -----------------------------------------------------------------------------
 * Field Group
 * ----------------------------------------------------------------------------- */

.fieldGroup {
  margin-bottom: 24px;

  @media (--medium) {
    display: flex;
  }
}

/* -----------------------------------------------------------------------------
 * Field
 * ----------------------------------------------------------------------------- */

.field {
  flex: 1;
}

.field + .field {
  margin-top: 16px;

  @media (--medium) {
    margin-top: 0;
    margin-left: 16px;
  }
}


/* -----------------------------------------------------------------------------
 * Label
 * ----------------------------------------------------------------------------- */

.label {
  display: block;
  margin-bottom: 8px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var( --color-black );
  color: var( --color-cyan-1 );
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;

  @media (--medium) {
    display: inline-block;
    text-align: left;
  }
}

/* -----------------------------------------------------------------------------
 * Input
 * ----------------------------------------------------------------------------- */

@define-mixin input {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: 0;
  border-radius: 6px;
  background-color: var( --color-mercury );
  font-size: rem( 20px );
  font-weight: 100;
}

.input {
  @mixin input;

  height: 60px;
}


/* -----------------------------------------------------------------------------
 * Textarea
 * ----------------------------------------------------------------------------- */

.textarea {
  @mixin input;

  padding: 16px;
}

/* -----------------------------------------------------------------------------
 * Submit
 * ----------------------------------------------------------------------------- */

.submit {
  text-align: center;
}

/* -----------------------------------------------------------------------------
 * Submit
 * ----------------------------------------------------------------------------- */

.submitBtn {
  display: block;
  width: 100%;

  @media (--large) {
    display: inline-block;
    width: auto;
  }
}
